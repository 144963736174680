.wsdown-menuopner {
  display: none;
}
.wsdownopener-sub {
  display: none;
}
.wsdexpander {
  display: none;
}
.wsdownopener-sub {
  display: block;
}
/*Main UL*/
.wsmenu > input:checked + .wsmenu__list {
  max-height: 1000px;
}
/* ================== Mobile Main Menu CSS ================== */
.wsmenu > .wsmenu__list {
  float: none;
  max-height: 0;
  overflow: hidden;
  list-style: none;
  transition: max-height 1s;
}
/*# sourceMappingURL=css/menu-lib.css.map */